/**
 * Main corporate js
 *
 *
 * useful links:
 *
 * - gradient: https://cssgradient.io/
 * - webpack: https://webpack.js.org/
 * - bootstrap: https://getbootstrap.com/docs/5.3
 * - fontawesome: https://fontawesome.com/
 * - aos: https://michalsnik.github.io/aos/
 */

import { MRT, MRTC } from './modartt_corporate.js'

window.MRT = MRT;
window.MRTC = MRTC;

MRTC.isReady = MRTC.initialize(window);

MRTC.isReady.then(()=>{
	if(MRT.Debug) MRT.Debug.isReady.then(()=>{
		MRTC.initDebugService = (service_name)=>{
			return MRT.Debug.initService(service_name, null, MRTC.service)
		};

		MRT.Debug.logDependencies();
	});
})

export { MRT, MRTC }
