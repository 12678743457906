
class Session {

	#mrtc;

	constructor(mrtc){
		this.#mrtc = mrtc;
	}

	async login(_options){
		const options = Object(_options)

		const data = await this.#mrtc.service('session',{login_box:1, goto:options.go || options.goto});

		const id = options.id || 'login-modal';
		var modal = this.#mrtc.modals.login;

		if(!modal){
			modal = this.#mrtc.modals.login = new this.#mrtc.MRT.ui.Modal({id:id, backdrop:options.static?'static':true, keyboard:false, show:true});

			modal.get('body').classList.add('text-center');
			modal.get('footer').hidden = true;
		}

		modal.content(data.html);

		const form = modal.get('body').querySelector('form.mrt-login-box, form.login-box');
		if(!form) throw "Malformed login box";

		const inputs = Array.from(form.querySelectorAll('input[type]')).map((e)=>{
			e.autocomplete = false;
			return e;
		});

		const self = this;
		form.onsubmit = function(){
			const form = this;
			self.#mrtc.service('session', {connect:1, login:form.login.value, password:form.password.value}).then((data)=>{
				inputs.forEach((e)=>{ e.autocomplete = true});
				if(!options.noclose) modal.close();
				if(options.onsuccess instanceof Function) options.onsuccess({data:data, form:form, modal:modal});
				else form.submit();
			},(err)=>{
				modal.message(err.message || err);
			});
			return false;
		};

		if(options.title) modal.title(options.title, options.type);
		if(options.static) modal.bootstrap._config.backdrop = options.static ? 'static' : true;
		if(options.info) form.insertAdjacentHTML('beforebegin','<div class="mrt-login-info text-start p-2">'+options.info+'</div>');

		modal.open();
		return modal;
	}

	async logout (){
		await this.#mrtc.service('session', {logout:1, action:'logout'});

		const page = this.#mrtc.MRT.info('page')
		const modal = await this.#mrtc.login({title:'Your session is closed, you can reconnect', type:'success', go:page});
		const footer = modal.get('footer');

		var cancel_btn = modal.element.querySelector('.btn-cancel');
		if(!cancel_btn){
			footer.insertAdjacentHTML('afterbegin','<a class="btn btn-primary btn-cancel" href="#">Cancel</a>');

			cancel_btn = footer.firstElementChild;
			cancel_btn.addEventListener('click', function(){
				modal.close();
			})
		}

		return modal;
	}

	handleForm(form, defaultParams){
		const MRT = this.#mrtc.MRT;
		const self = this;
		form.onsubmit = function(){
			if(!form.checkValidity()) return false;

			const params = Object.assign({connect:1, login:form.umail.value, password: form.upass.value}, defaultParams || {});

			if(form.remember) params.remember = form.remember.checked;

			const promise = self.request(params);
			MRT.loading(promise, 'Authorizing credentials');
			MRT.message(promise);
			return false;
		};
	}

	async request(params){
		const MRT = this.#mrtc.MRT;
		const data = await this.#mrtc.service('session', params);
		await MRT.delay(500);
		document.location = (MRT.info('page') || 'user_area')+document.location.search;
		return [document.location, data];
	}
}
	

export { Session }