import { MRT, MRTUI } from 'modartt-web/src/js/__modartt_ui.js'
import { Session} from './session.js';

const MRTC = {
	version: '2.60',
	date: '2024-02-29',
	url: 'https://www.modartt.com',
	forum_url: 'https://forum.modartt.com',
	api_url: 'api/0',
	modals: {},
	loaded: {},
	MRT: MRT
};

MRTC.message_buffer = [];

MRTC.info = MRT.info;

MRTC.Exception = class extends MRT.Exception {

	constructor(message, reference){
		super(message, reference);
	}

	displayMessage(type){
		MRT.message(this.message, type || 'error');
	}
}

MRTC.Element = class extends MRT.Element {
	constructor(type, element){
		super(type, element)
		element.classList.add('mrtc');
	}
}

MRTC.Session = new Session(MRTC);
MRTC.login = options => MRTC.Session.login(options);
MRTC.logout = options => MRTC.Session.logout(options);


MRTC.play = async (url, _options)=>{
	const options = Object(_options);

	const player = options.player || document.querySelector('audio');
	if(!player) throw new Error('No media player called');

	const target = options.ref;
	const last = player.__last || null;

	if(target instanceof HTMLElement){
		player.ref = target;
		if(!url) url = target.href || target.src || target.dataset.mrtcSource;
	}

	if(!(player instanceof HTMLMediaElement)) throw new Error("This is not a player");

	if(options.stop || !url){
		player.pause();
	}
	else if(!options.rewind && last && (url==last)) {
		if(player.paused) return await player.play();
		else {
			player.pause();
		}
	}
	else{
		player.src = url;
		player.__last = url;
		if(!options.loadonly) return await player.play();
	}

	return 'paused';
};

MRTC.client = {
	getLanguage : function(){ return navigator.language || navigator.userLanguage || navigator.languages[0] },
	isIE : function(){ return /explorer/i.test(navigator.appName) },
	isMac : function(){ return /^Mac/.test(navigator.platform) },
	isLinux : function() { return /^linux/i.test(navigator.platform) },
	isWindows : function() { return /^win\d+/i.test(navigator.platform) },
	isOld : function(){
		var match;
		return (
			(/MSIE\s+[1-8]\./i.test(navigator.appVersion)) ||
			(navigator.appName=="Opera" && parseInt(navigator.appVersion)<9) ||
			((match=navigator.appVersion.match(/webkit\/(\d+)/i))!=null && parseInt(match[1])<534) ||
			((match=navigator.userAgent.match(/firefox\/(\d+)/i))!=null && parseInt(match[1])<4)
		);
	},
	is64bit : function() { return navigator.platform=="MacIntel" || /x86_64/.test(navigator.platform) || /WOW64/.test(navigator.appVersion) || (navigator.oscpu && /WOW64|x86_64/.test(navigator.oscpu)) },
	isIPad : function() { return /^iPad/.test(navigator.platform) },
	isIPhone : function() { return /^iPhone/.test(navigator.platform) },
	isMobile : function() { return /(^s60|armv\d+$|android|Pike)/i.test(navigator.platform) || this.isIPhone() || this.isIPad() }
};

MRTC.news = async (count)=>{
	return await MRTC.service('news',{count:count});
};

MRTC.log = async (source, _options)=>{
	const options = Object(_options);
	const context = options.context || MRT.info('page');
	const params = {ref:context};
	const service = 'log';
	params.analytics = options.analytics?source:null;

	var matched;

	matched = source.match(/video:(.+?):(.+)/);
	if(matched){
		params.video = matched[1];
		params.id = matched[2];
		return await MRTC.service(service, params);
	}
	matched = source.match(/\.(mp(eg)?3|ogg|wav|flac)$/);
	if(matched){
		params.file = source.replace(/^(.*?\/)+/,'');
		params.type = 'audio';
		return await MRTC.service(service, params);
	}

	throw new Error('Format not supported');
};

MRTC.service = (service, params, _options)=>{
	const api_url = MRT.info('api-url') || MRTC.api_url || 'api';
	const options = {type:'json'};
	if (typeof(_options)=='boolean') options.verbose = _options;
	else Object.assign(options, Object(_options));
	if(service instanceof Array){
		service = service[0];
		if(service.length>=2) params.action = service[1];
		if(service.length>=3) params.what = params.property = service[2];
	}
	return MRT.service(api_url+'/'+service, params, options);
};

MRTC.debugService = async (service, params, _options)=>{
	try {
		const data = await MRTC.service(service, params, _options);
		MRTUI.JSONModal ? MRTUI.JSONModal(data) : MRT.debug('service '+service,data);
	}
	catch(err){
		MRT.debug('Error', err);
		MRT.message(err);
	}
};

MRTC.downloadResource = async (mixed, options)=>{
	var file;
	if(typeof mixed == 'string'){
		let res = await fetch(mixed);
		mixed = await res.blob()
	}

	if(mixed instanceof File){
		file = mixed;
	}
	else if(mixed instanceof Blob){
		file = new File([mixed], options.name, {type:options.name});
	}

	const a = document.createElement('a');
	a.href = URL.createObjectURL(file);
	a.download = file.name;
	a.click();

	return file;
}

MRTC.upload = async (service, params, options)=>{
	return await MRTC.service(service, params, Object.assign(Object(options), {type:'upload'}));
}

MRTC.loadLatestForumTopics = async (_options)=>{
	const options = Object(_options);
	const url = options.url || (MRTC.forum_url+'/extern.php?action=feed');

	const data = await MRT.service(url, {fid:1, crossaccess:1, show:options.limit || 10});
	if(!data || data.length==0) throw new Error('No forum topic');

	const forum_latest_threads = document.getElementById('forum_latest_threads');
	if(forum_latest_threads) forum_latest_threads.innerHTML = data;

	return data;
};

MRTC.openVideo = (video_id, _options)=>{
	const options = Object(_options);
	const modal = options.modal || MRTC.modals.video;
	const panel = options.container || document.getElementById('video_panel');
	const iframe = panel.querySelector('iframe');
	const platform = options.platform || 'youtube';

	if(!iframe) return;

	modal.title(options.title || '');

	const loading = panel.querySelector('.loading');
	loading.classList.add('fade');
	loading.classList.remove('d-none');

	const search_params = new URLSearchParams(options.params || {});
	const ytBaseUrl = 'https://www.youtube.com/embed';
	const vimeoBaseUrl = 'https://player.vimeo.com/video';
	var src;
	switch(platform){
		case 'youtube':
			if(video_id.match(/^PL/))
				src = ytBaseUrl+'/videoseries?list='+video_id+'&'+search_params;
			else
				src = ytBaseUrl+'/'+video_id+'?'+search_params;
			break;
		case 'vimeo':
			src = vimeoBaseUrl+'/'+video_id+'?'+search_params;
			break;
		default:
			throw new Error('Unknown video platform');
	}

	iframe.src = src;

	const video_name = options.name || null;
	if(video_name){
		const templates = options.templateContainer || document.getElementById('extra_info');

		modal.element.querySelectorAll('.video-extra').forEach((e)=>{ e.remove() });

		for(let elt of templates.children){
			const author = elt.dataset.name;
			if(!video_name.match(new RegExp('^'+author))) break;

			const _body = modal.get('body');
			_body.insertAdjacentHTML('beforeend', elt.outerHTML);
			_body.lastElementChild.classList.add('video-extra');
		}
	}

	iframe.addEventListener('load', function(){
		loading.classList.add('d-none');
		if(options.onload instanceof Function) options.onload.call(modal, {id:video_id, name:video_name, platform:platform});
		try {
			MRTC.log('video:'+platform+':'+video_id, {analytics:true});
		}
		catch(e){
			//...
		}

	});

	modal.open();
};

MRTC.iOSversion = ()=>{
	const is_ios = [
		'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'
	].includes(navigator.platform)
	// iPad on iOS 13 detection
	|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

	if(!is_ios) return undefined;

	const _w = MRTC.window;

	if(_w.indexedDB) return 8;
	if(_w.SpeechSynthesisUtterance) return 7;
	if(_w.webkitAudioContext) return 6;
	if(_w.matchMedia) return 5;
	if(_w.history && 'pushState' in _w.history) return 4;

	return 0;
};


MRTC.GDPR = {
	storage_key: 'modartt-privacy',
	service: async (_params)=>{
		const params = Object(_params);
		params.action = 'gdpr';
		return await MRTC.service('infos', params);
	},
	showDialog: async (html)=>{
		const modal_id = 'privacy-modal';
		var modal = document.getElementById(modal_id);
		if(!modal){
			modal = document.createElement('div');
			modal.id = modal_id;
			modal.classList.add('fixed-right-panel','fade');
			modal.innerHTML = html.trim();
			document.body.append(modal);
			const btn = modal.querySelector('.btn-accept')
			if(btn) btn.onclick = function(){
				modal.classList.remove('show');
				modal.hidden = true;
				MRT.storageL(MRTC.GDPR.storage_key,'read');
			};
		}
		modal.hidden = false;
		await MRT.delay(1000);
		modal.classList.add('show');
	},
	reset: async ()=>{
		MRT.storageL(MRTC.GDPR.storage_key, '');
		await MRTC.GDPR.verify();
	},
	verify: async ()=>{
		let gdpr = MRT.storageL(MRTC.GDPR.storage_key);
		if(!gdpr){
			MRTC.GDPR_promise = MRTC.GDPR.service();
			try {
				const data = await MRTC.GDPR_promise;
				await MRTC.GDPR.showDialog(data.html);
			}
			catch(err){
				MRT.debug(err);
			}
		}
	}
}

MRTC.loadModule = async (id, root, search)=>{
	if(MRTC.loaded[id]){
		return false;
	}
	var p = (root||'./')+'assets/js/'+id+'.mrtc.js';
	if(search) p+='?'+search;

	await MRT.getScript(p);
	MRTC.loaded[id] = p;
	return true;
}

MRTC.initGA4 = async ()=>{

	const id = MRT.info('ga');
	if(!id) return;

	const _w = MRTC.window;
	const dataLayer = _w.dataLayer || [];
	dataLayer.push(['js', new Date()]);
	dataLayer.push(['config', id]);
	_w.dataLayer = dataLayer;

	return await MRT.getScript('https://www.googletagmanager.com/gtag/js?id='+id);
};

MRTC.Menu = class extends MRTC.Element{

	constructor(element, value){
		super('menu', element);
		this.select(value);
	}

	select(value){
		if(value===undefined) value = this.element.dataset.mrtcValue;
		this.element.querySelectorAll(':scope.mrtc-menu a').forEach((e)=>{
			const id = e.dataset.mrtcId || e.href.replace(/.+?\//g,'')
			e.classList.toggle('selected', id == value);
		});
	}

	getSelected(){
		return Array.from(this.element.querySelectorAll('a.selected'));
	}
}

MRTC.verifyHuman = async (force)=>{
	if(!MRTC.captchaIsReady) await MRTC.loadModule('captcha');
	const c = await MRTC.captchaIsReady;
	const p = force ? c.ask() : c.verify();
	return await p;
}

MRTC.declareModal = (modalId, options, initFunc)=>{
	if(MRTC.modals[modalId]) return modalId;
	const modal = new MRT.ui.Modal(options);
	if(initFunc instanceof Function) initFunc.call(modal, modal);
	MRTC.modals[modalId] = modal;
	return modal;
}

MRTC.autoLoadModules = ()=>{

	const promises = [];

	// Affilae click handle
	const af_pid = MRT.info('af');
	if(af_pid){
		let p = MRTC.loadModule('affiliation').then(()=>{
			return MRTC.Affilae.init(af_pid)
		});
		promises.push(p);
	}

	// Product page
	// if(document.body.classList.contains('mrtc-product-page')){
	// 	let p = MRTC.loadModule('products').then(()=>{

	// 	}, (err)=>{
	// 		MRT.message('Unable to load Product module');
	// 		throw err;
	// 	});
	// 	promises.push(p);
	// }
	return promises;
}

MRTC.initialize = async (_window)=>{
	if(MRTC.initialized) return;

	await MRTUI.initialize();

	const _w = _window || MRT.window || window;
	MRTC.window = _w;

	const ios = MRTC.iOSversion();
	if(ios!==undefined){
		document.body.classList.add('mrtc-ios');
		if(ios>0) document.body.classList.add('mrtc-ios-'+ios);
	}

	const page = MRT.info('page');
	const api_url = MRT.info('api_url');
	if(api_url) MRTC.api_url = api_url;

	// Modules
	const modules = MRT.info('modules');

	if(modules) modules.split(',').forEach((m)=>{
		MRTC.loadModule(m);
	})

	const message_panel = document.getElementById('messages');
	if(message_panel){
		message_panel.classList.add('mrtc-message-box','fixed-top','container-lg');
		Array.from(message_panel.getElementsByClassName('alert')).forEach((e)=>{
			e.classList.add('clickable');
			e.onclick = function(){
				MRT.ui ? MRT.ui.vanish(e) : e.remove();
			}
		})
	}

	if(MRTC.messageBuffer){
		MRT.delay(1000).then(()=>{
			Array.from(MRTC.messageBuffer).forEach((d)=>{
				MRT.message(d.message, {'type':d.type, 'lifetime':0});
			});
		})
	}

	// Alternative source for image, video and audio
	document.querySelectorAll('[data-alt]').forEach((e)=>{
		const alt = e.dataset.alt; if(!alt) return;
		e.addEventListener('error', function(){
			this.src = this.dataset.alt;
		})
	})

	// SVG support
	MRTC.svgList = Array.from(document.querySelectorAll('div.mrt-svg[data-mrt-src]')).map((e)=>{
		return new MRT.SVG(e.dataset.mrtSrc, e);
	})

	// Dynamic menu
	if(!document.body.classList.contains('mrtc-no-sticky-nav')){
		let menuChanged = null;
		_w.addEventListener('scroll', function(){
			if(menuChanged) return;

			menuChanged = true;

			MRT.delay(500).then(()=>{
				const sticky = _w.scrollY > _w.screen.height;
				document.body.classList.toggle('mrtc-sticky-nav', sticky);
				menuChanged = false;
			})
		})
	}

	// Brand logo
	// const brand_logo = document.querySelector('#main-menu .mrtc-brand > a');
	// if(brand_logo && window.bootstrap){
	// 	brand_logo.onclick = function(event){
	// 		let e = document.querySelector('#center-menu');
	// 		if(!e || window.innerWidth>768) return true;

	// 		let b = window.bootstrap.Collapse.getOrCreateInstance(e);
	// 		b.toggle();
	// 		event.preventDefault();
	// 	}
	// }

	// Menu toggler
	document.querySelectorAll('[data-mrtc-toggle="smart-menu"]').forEach((e)=>{

		e.onclick = function(){
			let cm = document.getElementById('center-menu');
			cm.classList.toggle('show');

			let b = cm.classList.contains('show');
			this.classList.toggle('active', b);
		}
	})

	// Infos
	const ib = document.querySelector('a[href="#footer"]');
	if(ib){
		let modal = ()=>{
			if(MRTC.modals.info) return MRTC.modals.info;
			let id = 'mrtc-modal-info';
			let f = document.getElementById(id);
			let m = new MRT.ui.Modal(f || {id:id, 'title':'Information'});
			m.element.querySelector('.modal-dialog').classList.add('modal-lg', 'modal-fullscreen-lg-down');
			m.content(document.getElementById('footer').innerHTML);
			m.get('footer').remove();
			let b = m.get('body');
			let l = b.querySelector('.mrt-brand-logo');
			l.src = 'images/logo/modartt-black.svg';
			b.querySelectorAll('.row > div').forEach((e)=>{
				e.className = 'col-4 p-3';
			});
			MRTC.modals.info = m;
			return m;
		}

		ib.onclick = function(){
			modal().open();
			return false;
		}
	}

	// Bootstrap
	document.querySelectorAll('[data-bs-toggle]').forEach((e)=>{

		e.bootstrap = MRTUI.bootstrapElement(e, e.dataset.bsToggle);
	})


	// Logs
	document.querySelectorAll('[data-log][href]').forEach((e)=>{
		const log_res = e.dataset.mrtcLog || e.dataset.log || e.href;
		if(log_res) e.addEventListener('click',function(){
			MRTC.log(log_res, {analytics:true});
		})
	})

	const main_menu = document.getElementById('main-menu');

	//Highlight menu
	const pages = [page, page.replace(/_\w+/,''), page.replace(/_\w+/,'_overview')];
	for(let p of pages){
		var q = false;
		for(let e of document.querySelectorAll('.navbar-nav a')){
			const ref = e.getAttribute('href') || e.dataset.mrtcPage;

			if(ref==p){
				e.classList.add('active')
				let navItem = e.closest('.nav-item');
				if(navItem) navItem.classList.add('active');
				q = true;
				break;
			}
		}
		if(q) break;
	}

	// Hover on menu
	//@todo
	document.querySelectorAll('#center-menu.mrtc-trigger-hover .dropdown-toggle').forEach((e)=>{

		e.addEventListener('mouseenter', function(){
			this.parentElement.querySelector('ul.dropdown-menu').classList.add('show');
		})

		e.addEventListener('mouseleave', function(){
			this.parentElement.querySelector('ul.dropdown-menu').classList.remove('show');
		})
	})

	// Hash & Tab
	{
		let find_tab = (name)=>{
			const f = document.getElementById('tab_'+name) ||
				document.querySelector('.nav-item[name="'+name+'"] .nav-link') ||
				document.querySelector('.nav-link[href="#'+name+'"]') ||
				document.querySelector('.nav-link[data-mrtc-tab="'+name+'"]');
			if(f) return f;
			let m = name.match(/^(\w+)_(.+)/);
			return m ? find_tab(m[2]) : null;
		};
		let show_tab = (e)=>{
			if(e.classList.contains('active')) return;
			if(e.tagName == 'A' && e.getAttribute('href')==page) return;
			e.click && e.click();
		};
		let hash = document.location.hash.substr(1);
		let found_tab = hash.length ? find_tab(hash) : null;
		if(found_tab) show_tab(found_tab);

		_w.addEventListener('hashchange', function(){
			const hash = document.location.hash.substr(1);
			if(!hash) return;
			const _f = find_tab(hash);
			if(!_f || _f ===found_tab) return;
			show_tab(_f);
		});
	}

	// GDPR
	if(main_menu && MRT.info('gdpr')=='warn'){
		if(document.location.hash=='#privacy_warning') MRTC.GDPR.reset();
		else MRTC.GDPR.verify();
	}

	try {
		if(MRTC.initGA4) await MRTC.initGA4();
	}
	catch(e){
		MRT.debug('GA4', e);
	}

	MRT.lib.MRTC = MRTC.version;

	// Session
	MRTC.Session.isReady = (async ()=>{

		const sr = MRT.info('session-require');
		if(sr=='login'){
			let modal = await MRTC.login({title:'Please sign in (the session may have expired)', noclose:true, go:MRT.info('page')});
			let close_btns = modal.get('header').querySelectorAll('.btn-close');
			close_btns.forEach((e)=>{
				e.onclick = function(){
					window.location = 'user_area';
				}
			})
		}

	})();

	// Lightbox
	const lightbox_elts = document.querySelectorAll('[data-mrt-toggle=lightbox]');
	if(lightbox_elts.length){
		MRTC.loadModule('lightbox').then(()=>{
			return MRTC.Lightbox.init();
		});
	}

	MRTC.autoLoadModules();


	MRTC.initialized = true;
	_w.dispatchEvent(new CustomEvent('MRTCLoaded'));
	return true;
};

export { MRT, MRTUI, MRTC }
